import Vue from 'vue'
import VueRouter from 'vue-router'
/*import { getItem, setItem } from "@/utils/storage"

import store from '@/store'*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
    {

        path: '/',
        name:'/',
        component: () => import('@/views/company'),
        redirect: '',
    },
    {

        path: '/wxLogin',
        name:'wxLogin',
        component: () => import('@/views/wxLogin'),
        redirect: '',
    },
    {

        path: '/order',
        name:'order',
        component: () => import('@/views/order'),
        redirect: '',
    },
    {

        path: '/bindPhone',
        name:'bindPhone',
        component: () => import('@/views/bindPhone'),
        redirect: '',
    },
    {

        path: '/login',
        name:'login',
        component: () => import('@/views/login'),
        redirect: '',
    },
    {

        path: '/newlogin',
        name:'newlogin',
        component: () => import('@/views/new/newlogin'),
        redirect: '',
    },
    {

        path: '/companyproduce',
        name:'companyproduce',
        component: () => import('@/views/new/companyproduce'),
        redirect: '',
    },
    {

        path: '/videoproduce',
        name:'videoproduce',
        component: () => import('@/views/new/videoproduce'),
        redirect: '',
    },
    {

        path: '/classdirectory',
        name:'classdirectory',
        component: () => import('@/views/new/classdirectory'),
        redirect: '',
    },
    {

        path: '/classcontent',
        name:'classcontent',
        component: () => import('@/views/new/classcontent'),
        redirect: '',
    },
    {

        path: '/newsdetail',
        name:'newsdetail',
        component: () => import('@/views/new/newsdetail'),
        redirect: '',
    },
    {

        path: '/schoolsdetail',
        name:'schoolsdetail',
        component: () => import('@/views/new/schoolsdetail'),
        redirect: '',
    },
    {

        path: '/schools',
        name:'schools',
        component: () => import('@/views/new/schools'),
        redirect: '',
    },
    {

        path: '/videoPlayer',
        name:'videoPlayer',
        component: () => import('@/views/new/videoPlayer'),
        redirect: '',
    },
    {

        path: '/company',
        name:'company',
        component: () => import('@/views/company'),
        redirect: '',
    },
    {

        path: '/companynews',
        name:'companynews',
        component: () => import('@/views/companynews'),
        redirect: '',
    },
    {
        path: '/list',
        name:'list',
        component: () => import('@/views/home/index'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/videoplay',
        name:'videoplay',
        component: () => import('@/views/home/videoPlay'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/phonelist',
        name:'phonelist',
        component: () => import('@/views/phone/list'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/phonelist2',
        name:'phonelist2',
        component: () => import('@/views/phone/list2'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/logincontent',
        name:'logincontent',
        component: () => import('@/views/new/logincontent'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/tearcheroom',
        name:'tearcheroom',
        component: () => import('@/views/new/teacherroom'),
        redirect: '',
        children:[


        ]
    },
    {
        path: '/zhengcequdong',
        name:'zhengcequdong',
        component: () => import('@/views/new/zhengcequdong'),
        redirect: '',
        children:[


        ]
    },{
        path:'/wulut',
        name:'wulut',
        component:()=>import('@/views/wulut'),
        redirect:'',
    },{
        path:'/orderserver',
        name:'orderserver',
        component:()=>import('@/views/orderserver'),
        redirect:'',
    }




]
const router = new VueRouter({

    base: '/',
    routes
})


router.beforeEach((to, from, next) => {

    next();


})

export default router
