import Vue from 'vue'
import App from './App.vue'

import axios from '@/axios'
import router from '@/router'
import store from '@/store'   //导入store
import Antd from 'ant-design-vue';
import { message } from 'ant-design-vue'
import utils from '@/utils'

import 'ant-design-vue/dist/antd.css';
import moment from 'moment'
import LocaleProvider from 'ant-design-vue'
import {export2Excel } from '@/excel/excelFu'
import { getItem,removeItem } from "@/utils/storage"
import VideoPlayer from 'vue-video-player'
import {getSessionItem} from "./utils/storage";
import  SoftKey3W  from "@/utils/Syunew3W";

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import '@/utils/register-vant'// 注册Vant组件
import '@/utils/validation'
import '@/utils/datetime'
import vueSwiper from 'vue-awesome-swiper'  //引入vue-awesome-swiper
import 'swiper/dist/css/swiper.css'  //引入样式



Vue.use(vueSwiper)  //使用插件

Vue.use(VideoPlayer)
Vue.prototype.$excel=export2Excel;
Vue.use(LocaleProvider)
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$message=message
Vue.prototype.$utils=utils

Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// //路由守卫
router.beforeEach((to, from, next)  => {

/*  if(to.name == "/"){ // 如果不需要权限校验，直接进入路由界面
    next();
  }else {
    // 判断该路由是否需要登录权限
    if (getItem("mn")) {  // 获取当前的token是否存在
      if (to.meta.Authorization){
        message.warn('您没有登录!')
      }else{
        next();
      }

    } else {
      message.warn('请登录!')
      router.push({name:'/'})
    }
     /!* if (getItem("Token")) {  // 获取当前的token是否存在
     next();

    } else {
      message.warn('您没有权限!')
      router.push({name:'/'})
    }  *!/
  }*/
  next();
});

/*Vue.prototype.ukeyLoad=function () {
  var that=this;
  //navigator.userAgent.indexOf("MSIE")>0 && !navigator.userAgent.indexOf("opera") > -1
  if(navigator.userAgent.indexOf("MSIE")>0 && !navigator.userAgent.indexOf("opera") > -1)
  {
    var s_simnew1=new ActiveXObject("Syunew3A.s_simnew3");
    var ukeylisten=setInterval(() => {
      var DevicePath = s_simnew1.FindPort(0);
      if( s_simnew1.LastError!= 0 )
      {
          clearInterval(ukeylisten);
          removeItem("mn");
          removeItem("un");
          removeItem("Token");
          that.$router.push({ path: "/" });
      }
    }, 1000);
  }
  else
  {
    var bConnect=0
    try
    {
      var s_pnp=new SoftKey3W();
      s_pnp.Socket_UK.onopen = function()
      {
        bConnect=1;
      }
      s_pnp.Socket_UK.onmessage =function got_packet(Msg)
      {
        var PnpData = JSON.parse(Msg.data);
        if(PnpData.type=="PnpEvent")
        {
          if(PnpData.IsIn)
          {

          }
          else
          {
            removeItem("mn");
            removeItem("un");
            removeItem("Token");
            that.$router.push({ path: "/" });
          }
        }
      }
    }
    catch(e)
    {
      alert(e.name + ": " + e.message);
      return false;
    }
  }

}*/
