import Vue from 'vue'

import {
  Button,
  Cell,
  CellGroup,
  NavBar,
  Field,
  Toast,
  CountDown,
  Icon,
  Tabbar,
  TabbarItem,
  Image,
  Grid,
  GridItem,
  Dialog,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Popup,
  Search,
  Loading,
  Divider,
  ActionSheet,
  DatetimePicker,
  ImagePreview,
  Card,
  Tag,
  AddressList,
  AddressEdit,
  Area,
  Switch,
  Uploader ,
  Sidebar, 
  SidebarItem ,
  Slider,
  Checkbox, 
  CheckboxGroup,
  Stepper,
  DropdownMenu, 
  DropdownItem,
  Swipe,
  SwipeItem,
  Lazyload,
  SubmitBar,
  SwipeCell,
 
  Picker,
  Empty,
  Image as VanImage,
  RadioGroup, Radio,
  Rate,
  GoodsAction,
  GoodsActionIcon, 
  GoodsActionButton,
  Sticky,
  TreeSelect 
 

} from 'vant'
Vue
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(NavBar)
  .use(Field)
  .use(Toast)
  .use(CountDown)
  .use(Icon)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Image)
  .use(Grid)
  .use(GridItem)
  .use(Dialog)
  .use(Tab)
  .use(Tabs)
  .use(List)
  .use(PullRefresh)
  .use(Popup)
  .use(Search)
  .use(Loading)
  .use(Divider)
  .use(ActionSheet)
  .use(DatetimePicker)
  .use(ImagePreview)
  .use(Card)
  .use(Tag)
  .use(AddressList)
  .use(AddressEdit)
  .use(Area)
  .use(Switch)
  .use(Uploader)
  .use(Sidebar)
  .use(SidebarItem )
  .use(Slider)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Stepper)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(SubmitBar)
  .use(SwipeCell)
  .use(Picker)
  .use(Empty)
  .use(VanImage)
  .use(RadioGroup)
  .use(Radio)
  .use(Rate)
  .use(GoodsAction)
  .use(GoodsActionIcon)
  .use(GoodsActionButton)
  .use(Sticky)
  .use(TreeSelect)



 
